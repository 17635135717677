/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:9b88a270-95a0-41a5-ab47-0cf6d8e9db94",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_niUtVOZKv",
    "aws_user_pools_web_client_id": "5kf73h2ci6lpi8bteuhcept09q",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fbn5gwqqejg3bftazkgoylxshi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "chikidppp55019-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
